<template>
  <div class="d-flex flex-row">
    <ProfileAside></ProfileAside>
    <div class="flex-row-fluid ml-lg-8">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder text-dark">
              Personal Information
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1"
              >View your personal informaiton</span
            >
          </div>
          <div class="card-toolbar">
            <router-link
              custom
              :to="{ name: 'profile-personal-information-edit' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <a
                :href="href"
                class="btn btn-light-primary font-weight-bolder px-7"
                @click="navigate"
              >
                Edit
              </a>
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form">
          <!--begin::Body-->
          <div class="card-body">
            <div class="row">
              <label class="col-xl-3"></label>
              <div class="col-lg-9 col-xl-6">
                <h5 class="font-weight-bold mb-6">Personal Info</h5>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right"
                >First Name</label
              >
              <div class="col-lg-9 col-xl-6">
                <input
                  ref="name"
                  class="form-control"
                  type="text"
                  disabled
                  :value="currentUser.firstName"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right"
                >Last Name</label
              >
              <div class="col-lg-9 col-xl-6">
                <input
                  ref="surname"
                  class="form-control"
                  type="text"
                  disabled
                  :value="currentUser.lastName"
                />
              </div>
            </div>
            <div class="row">
              <label class="col-xl-3"></label>
              <div class="col-lg-9 col-xl-6">
                <h5 class="font-weight-bold mt-10 mb-6">Contact Info</h5>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right"
                >Contact Phone</label
              >
              <div class="col-lg-9 col-xl-6">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="la la-phone"></i>
                    </span>
                  </div>
                  <input
                    ref="phone"
                    type="text"
                    class="form-control"
                    placeholder="Phone"
                  />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right"
                >Email Address</label
              >
              <div class="col-lg-9 col-xl-6">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="la la-at"></i>
                    </span>
                  </div>
                  <input
                    ref="email"
                    type="text"
                    class="form-control"
                    placeholder="Email"
                    disabled
                    :value="currentUser.email"
                  />
                </div>
              </div>
            </div>
          </div>
          <!--end::Body-->
        </form>
        <!--end::Form-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProfileAside from "./ProfileAside.vue";

export default {
  name: "PersonalInformation",
  components: {
    ProfileAside,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
