<template>
  <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px">
    <!--begin::Profile Card-->
    <div class="card card-custom">
      <!--begin::Body-->
      <div class="card-body mt-5 pt-5">
        <!--begin::User-->
        <div class="d-flex align-items-center">
          <div
            class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
          >
            <div
              class="symbol-label"
              style="background-image: url('/media/users/300_21.jpg')"
            ></div>
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column mb-auto">
            <a
              href="#"
              class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ currentUser.firstName }} {{ currentUser.lastName }}
            </a>
            <div class="text-muted">
              <span v-if="currentUser.roles[0] === 'ROLE_SUPER_ADMIN'"
                >Super Admin</span
              >
              <span v-if="currentUser.roles[0] === 'ROLE_ADMIN'">Admin</span>
              <span v-if="currentUser.roles[0] === 'ROLE_BABY_ADMIN'"
                >Baby Admin</span
              >
              <span v-if="currentUser.roles[0] === 'ROLE_USER'">User</span>
            </div>
          </div>
        </div>
        <!--end::User-->

        <!--begin::Contact-->
        <div class="py-9">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <span class="font-weight-bold mr-2">Email:</span>
            <a href="#" class="text-muted text-hover-primary">{{
              currentUser.email
            }}</a>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-2">
            <span class="font-weight-bold mr-2">Phone:</span>
            <span class="text-muted">-</span>
          </div>
        </div>
        <!--end::Contact-->

        <!--begin::Nav-->
        <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
          <div class="navi-item mb-2">
            <router-link
              custom
              :to="{ name: 'profile-personal-information' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <a
                :href="href"
                class="navi-link py-4"
                :class="[isActive && 'active', isExactActive && 'active']"
                @click="navigate"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/General/User.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">
                  Personal Information
                </span>
              </a>
            </router-link>
          </div>
        </div>
        <!--end::Nav-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::Profile Card-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProfileAside",
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
